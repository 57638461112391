export const routesMap = {
  home: '/',
  error: '/error',
  any: '*',
  settings: {
    short: 'settings',
    full: '/settings',
  },
  accounts: {
    short: 'accounts',
    full: '/accounts',
  },
  savings: {
    short: 'savings',
    full: '/savings',
  },
  cards: {
    short: 'cards',
    full: '/cards',
  },
  convertMoney: {
    short: 'convert-money',
    full: '/convert-money',
  },
  topup: {
    short: 'topup',
    full: '/topup',
  },
  withdraw: {
    short: 'withdraw',
    full: '/withdraw',
  },
  requestCard: {
    short: 'request-card',
    full: '/request-card',
  },
  notifications: {
    short: 'notifications',
    full: '/notifications',
  },
  password: {
    short: 'password',
    full: '/settings/password',
  },
  editProfile: {
    short: 'edit-profile',
    full: '/settings/edit-profile',
  },
  settingsNotifications: {
    short: 'notifications',
    full: '/settings/notifications',
  },
  savingsCreateAccount: {
    short: 'create-account',
    full: '/savings/create-account',
  },
  saving: {
    short: 'savings/:id',
    full: '/savings/:id',
  },
  savingTopup: {
    short: 'topup',
    full: '/savings/:id/topup',
  },
  savingWithdraw: {
    short: 'withdraw',
    full: '/savings/:id/withdraw',
  },
  savingConvertMoney: {
    short: 'convert-money',
    full: '/savings/:id/convert-money',
  },
  card: {
    short: 'cards/:id',
    full: '/cards/:id',
  },
  cardRequestCard: {
    short: 'request-card',
    full: '/cards/:id/request-card',
  },
  accountsTopup: {
    short: 'topup',
    full: '/accounts/topup',
  },
  accountsWithdraw: {
    short: 'withdraw',
    full: '/accounts/withdraw',
  },
  accountsConvertMoney: {
    short: 'convert-money',
    full: '/accounts/convert-money',
  },
  account: {
    short: 'accounts/:id',
    full: '/accounts/:id',
  },
  accountTopup: {
    short: 'topup',
    full: '/accounts/:id/topup',
  },
  accountWithdraw: {
    short: 'withdraw',
    full: '/accounts/:id/withdraw',
  },
  accountConvertMoney: {
    short: 'convert-money',
    full: '/accounts/:id/convert-money',
  },
  transactions: {
    short: 'transactions',
    full: '/transactions',
  },
  auth: '/auth',
  faq: {
    short: 'faq',
    full: '/faq',
  },
} as const;
