import { lazy, Suspense } from 'react';

import { PageLoader } from '@components/ui/PageLoader';

const EditProfilePageLazy = lazy(() => import('./edit-profile-page'));
const PasswordPageLazy = lazy(() => import('./password-page'));
const SettingsLayoutLazy = lazy(() => import('./layout'));
const NotificationsPageLazy = lazy(() => import('./notifications-page'));

const SettingsLayoutAsync = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <SettingsLayoutLazy />
    </Suspense>
  );
};

const EditProfilePageAsync = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <EditProfilePageLazy />
    </Suspense>
  );
};

const PasswordPageAsync = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <PasswordPageLazy />
    </Suspense>
  );
};

const NotificationSettingsAsync = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <NotificationsPageLazy />
    </Suspense>
  );
};

export {
  SettingsLayoutAsync as SettingsLayout,
  EditProfilePageAsync as EditProfilePage,
  PasswordPageAsync as PasswordPage,
  NotificationSettingsAsync as NotificationSettings,
};
